<!-- src/App.vue -->
<template>
  <div id="app">
    <!-- Loader-->
    <div id="page-preloader" v-if="isLoading">
      <div class="preloader-1">
        <div class="loader-text">Loading</div>
        <!-- 您的加载动画内容 -->
        <!-- ... -->
      </div>
    </div>
    <!-- Loader end-->

    <div class="page-wrapper" v-show="!isLoading">
      <HeaderComponent />
      <div class="page-content">
        <SidebarComponent />
        <!-- 使用 router-view 渲染路由组件 -->
        <router-view />
      </div>
    </div>

    <!-- 模态框 -->
    <ModalComponent />

    <!-- 切换主题的checkbox -->
    <input id="toggle" type="checkbox" @click="toggleTheme">
  </div>
</template>

<script>
import { ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import HeaderComponent from './components/HeaderComponent.vue';
import SidebarComponent from './components/SidebarComponent.vue';
import ModalComponent from './components/ModalComponent.vue';

export default {
  name: 'App',
  components: {
    HeaderComponent,
    SidebarComponent,
    ModalComponent,
  },
  setup() {
    const isLoading = ref(false); // 初始值设为 false

    const route = useRoute();

    // 监听路由变化
    watch(
      () => route.fullPath,
      () => {
        // 当路由变化时，显示加载动画
        isLoading.value = true;

        // 模拟加载时间，完成后设置 isLoading 为 false，隐藏加载动画
        setTimeout(() => {
          isLoading.value = false;
        }, 200); // 您可以根据需要调整延迟时间
      }
      // 移除了 { immediate: true }
    );

    const toggleTheme = () => {
      document.body.classList.toggle('dark-theme');
    };

    return {
      isLoading,
      toggleTheme,
    };
  },
};
</script>

<style>
/* 全局样式 */
</style>
