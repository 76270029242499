<template>
    <main class="page-main">
      <div class="uk-grid" data-uk-grid>
        <div class="uk-width-2-3@l">
          <div class="widjet --profile">
            <div class="widjet__head">
              <h3 class="uk-text-lead">Profile</h3>
            </div>
            <div class="widjet__body">
              <div class="user-info">
                <div class="user-info__avatar">
                  <img src="/assets/img/profile.png" alt="profile">
                </div>
                <div class="user-info__box">
                  <div class="user-info__title">John Doe</div>
                  <div class="user-info__text">Egypt, Member since May 2022</div>
                </div>
              </div>
              <router-link class="uk-button uk-button-danger" to="/edit-profile">
                <i class="ico_edit"></i>
                <span class="uk-margin-small-left">Edit Profile</span>
              </router-link>
            </div>
          </div>
  
          <div class="widjet --bio">
            <div class="widjet__head">
              <h3 class="uk-text-lead">Bio</h3>
            </div>
            <div class="widjet__body">
              <span>
                Here you can put your biography you need try to make it attractive and professional, just be honest and polite.
              </span>
            </div>
          </div>
  
          <div class="widjet --activity">
            <div class="widjet__head">
              <h3 class="uk-text-lead">Recent Activity</h3>
              <router-link to="/profile">View All</router-link>
            </div>
            <div class="widjet__body" v-for="(activity, index) in recentActivities" :key="index">
              <div class="widjet-game">
                <div class="widjet-game__media">
                  <router-link :to="activity.link">
                    <img :src="activity.image" alt="image">
                  </router-link>
                </div>
                <div class="widjet-game__info">
                  <router-link class="widjet-game__title" :to="activity.link">
                    {{ activity.title }}
                  </router-link>
                  <div class="widjet-game__record">{{ activity.record }}</div>
                  <div class="widjet-game__last-played">{{ activity.lastPlayed }}</div>
                </div>
              </div>
              <div class="widjet-game-info" v-if="activity.achievementProgress">
                <div class="widjet-game-info__title">Achievement Progress</div>
                <div class="widjet-game-info__progress">
                  <span>{{ activity.achievementProgress.completed }} of {{ activity.achievementProgress.total }}</span>
                  <div class="progress-box">
                    <div class="progress-line" :style="{ width: activity.achievementProgress.percentage + '%' }"></div>
                  </div>
                </div>
                <div class="widjet-game-info__acheivement">
                  <ul>
                    <li v-for="(achievement, idx) in activity.achievementProgress.achievements" :key="idx">
                      <img :src="achievement" alt="achievement">
                    </li>
                    <li><span>+{{ activity.achievementProgress.more }}</span></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
  
        <div class="uk-width-1-3@l">
          <div class="widjet --upload">
            <div class="widjet__head">
              <h3 class="uk-text-lead">Upload Item</h3>
            </div>
            <div class="widjet__body">
              <select class="js-select uk-flex-1">
                <option value="">Select a Category</option>
                <option value="Category 1">Category 1</option>
                <option value="Category 2">Category 2</option>
                <option value="Category 3">Category 3</option>
              </select>
              <button class="uk-button uk-button-secondary" type="button">Next</button>
            </div>
          </div>
  
          <div class="widjet --badges">
            <div class="widjet__head">
              <h3 class="uk-text-lead">Badges</h3>
              <router-link to="/profile">View All</router-link>
            </div>
            <div class="widjet__body">
              <ul class="badges-list">
                <li v-for="(badge, index) in badges" :key="index">
                  <img :src="badge" alt="badge">
                </li>
              </ul>
            </div>
          </div>
  
          <div class="widjet --games">
            <div class="widjet__head">
              <h3 class="uk-text-lead">Games</h3>
              <router-link to="/profile">View All</router-link>
            </div>
            <div class="widjet__body">
              <ul class="games-list">
                <li v-for="(game, index) in games" :key="index">
                  <img :src="game" alt="game">
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </main>
  </template>
  
  <script>
  export default {
    name: 'ProfileView',
    data() {
      return {
        recentActivities: [
          {
            link: '/game-profile',
            image: '/assets/img/activity-1.jpg',
            title: 'Chrome Fear',
            record: '3 hours on record',
            lastPlayed: 'last played on 18 Feb, 2022',
            achievementProgress: {
              completed: 50,
              total: 150,
              percentage: 80,
              achievements: [
                '/assets/img/acheivement-1.png',
                '/assets/img/acheivement-2.png',
                '/assets/img/acheivement-3.png',
                '/assets/img/acheivement-4.png',
                '/assets/img/acheivement-5.png',
              ],
              more: 10,
            },
          },
          {
            link: '/game-profile',
            image: '/assets/img/activity-2.jpg',
            title: 'Retaliate of Prosecution',
            record: '0.2 hours on record',
            lastPlayed: 'last played on 25 Apr, 2022',
            achievementProgress: null,
          },
        ],
        badges: [
          '/assets/img/badge-1.png',
          '/assets/img/badge-2.png',
          '/assets/img/badge-3.png',
        ],
        games: [
          '/assets/img/game-1.jpg',
          '/assets/img/game-2.jpg',
          '/assets/img/game-3.jpg',
          '/assets/img/game-4.jpg',
        ],
      };
    },
  };
  </script>
  
  <style scoped>
  /* 如果需要，可以在这里添加组件特定的样式 */
  </style>
  