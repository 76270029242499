<template>
    <main class="page-main">
      <!-- 筛选器部分 -->
      <div class="widjet --filters">
        <div class="widjet__head">
          <h3 class="uk-text-lead">Games Store</h3>
        </div>
        <div class="widjet__body">
          <div
            class="uk-grid uk-child-width-1-6@xl uk-child-width-1-3@l uk-child-width-1-2@s uk-flex-middle uk-grid-small"
            data-uk-grid
          >
            <div class="uk-width-1-1">
              <div class="search">
                <div class="search__input">
                  <i class="ico_search"></i>
                  <input type="search" name="search" placeholder="Search" v-model="searchQuery" />
                </div>
                <div class="search__btn">
                  <button type="button">
                    <i class="ico_microphone"></i>
                  </button>
                </div>
              </div>
            </div>
            <div>
              <select class="js-select" v-model="sortBy">
                <option value="">Sort By: Price</option>
                <option value="Price 1">Price 1</option>
                <option value="Price 2">Price 2</option>
                <option value="Price 3">Price 3</option>
              </select>
            </div>
            <div>
              <select class="js-select" v-model="category">
                <option value="">Category: Strategy</option>
                <option value="Category 1">Category 1</option>
                <option value="Category 2">Category 2</option>
                <option value="Category 3">Category 3</option>
              </select>
            </div>
            <div>
              <select class="js-select" v-model="platform">
                <option value="">Platform: All</option>
                <option value="Platform 1">Platform 1</option>
                <option value="Platform 2">Platform 2</option>
                <option value="Platform 3">Platform 3</option>
              </select>
            </div>
            <div>
              <select class="js-select" v-model="numberOfPlayers">
                <option value=""># of Players: All</option>
                <option value="1">1 Player</option>
                <option value="2">2 Players</option>
                <option value="3">3 Players</option>
              </select>
            </div>
            <div>
              <div class="price-range">
                <label>Price</label>
                <input
                  class="uk-range"
                  type="range"
                  v-model="priceRange"
                  :min="0"
                  :max="100"
                  step="1"
                />
              </div>
            </div>
            <div class="uk-text-right">
              <a href="#!">{{ filteredGames.length }} items</a>
            </div>
          </div>
        </div>
      </div>
  
      <!-- 游戏列表部分 -->
      <div
        class="uk-grid uk-child-width-1-6@xl uk-child-width-1-4@l uk-child-width-1-3@s uk-flex-middle uk-grid-small"
        data-uk-grid
      >
        <div v-for="(game, index) in filteredGames" :key="index">
          <div class="game-card">
            <div class="game-card__box">
              <div class="game-card__media">
                <router-link :to="game.link">
                  <img :src="game.image" :alt="game.title" />
                </router-link>
              </div>
              <div class="game-card__info">
                <router-link class="game-card__title" :to="game.link">
                  {{ game.title }}
                </router-link>
                <div class="game-card__genre">{{ game.genre }}</div>
                <div class="game-card__rating-and-price">
                  <div class="game-card__rating">
                    <span>{{ game.rating }}</span><i class="ico_star"></i>
                  </div>
                  <div class="game-card__price">
                    <span>{{ game.price }} </span>
                  </div>
                </div>
                <div class="game-card__bottom">
                  <div class="game-card__platform">
                    <i class="ico_windows" v-if="game.platform.includes('windows')"></i>
                    <i class="ico_apple" v-if="game.platform.includes('apple')"></i>
                  </div>
                  <div class="game-card__users">
                    <ul class="users-list">
                      <li v-for="(user, idx) in game.users" :key="idx">
                        <img :src="user" alt="user" />
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </template>
  
  <script>
  export default {
    name: 'GamesStoreView',
    data() {
      return {
        searchQuery: '',
        sortBy: '',
        category: '',
        platform: '',
        numberOfPlayers: '',
        priceRange: 50,
        games: [
          {
            title: 'Struggle of Rivalry',
            genre: 'Shooter / Platformer',
            rating: '4.8',
            price: '$4.99',
            link: '/game-profile',
            image: '/assets/img/game-1.jpg',
            platform: ['windows', 'apple'],
            users: [
              '/assets/img/user-1.png',
              '/assets/img/user-2.png',
              '/assets/img/user-3.png',
              '/assets/img/user-4.png',
            ],
          },
          {
            title: 'Hunt of Duplicity',
            genre: 'Action / Adventure',
            rating: '4.6',
            price: '$9.99',
            link: '/game-profile',
            image: '/assets/img/game-2.jpg',
            platform: ['windows', 'apple'],
            users: [
              '/assets/img/user-1.png',
              '/assets/img/user-2.png',
              '/assets/img/user-3.png',
              '/assets/img/user-4.png',
            ],
          },
          {
            title: 'Journey and Dimension',
            genre: 'Survival / Strategy',
            rating: '4.7',
            price: '$13.99',
            link: '/game-profile',
            image: '/assets/img/game-3.jpg',
            platform: ['windows', 'apple'],
            users: [
              '/assets/img/user-1.png',
              '/assets/img/user-2.png',
              '/assets/img/user-3.png',
              '/assets/img/user-4.png',
            ],
          },
          // 添加更多游戏数据
          // ...
        ],
      };
    },
    computed: {
      filteredGames() {
        // 简单的过滤示例，您可以根据需求添加更复杂的过滤逻辑
        return this.games.filter((game) => {
          // 搜索过滤
          const matchesSearch =
            this.searchQuery === '' ||
            game.title.toLowerCase().includes(this.searchQuery.toLowerCase());
  
          // 分类过滤
          const matchesCategory =
            this.category === '' || game.genre.includes(this.category);
  
          // 平台过滤
          const matchesPlatform =
            this.platform === '' || game.platform.includes(this.platform);
  
          // 价格过滤（假设价格是字符串，例如 "$4.99"）
          const gamePrice = parseFloat(game.price.replace('$', ''));
          const matchesPrice = gamePrice <= this.priceRange;
  
          return matchesSearch && matchesCategory && matchesPlatform && matchesPrice;
        });
      },
    },
  };
  </script>
  
  <style scoped>
  /* 如果需要，可以在这里添加组件特定的样式 */
  </style>
  