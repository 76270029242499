<template>
    <main class="page-main">
      <div class="uk-grid" data-uk-grid>
        <!-- 左侧内容区域 -->
        <div class="uk-width-2-3@xl">
          <!-- 筛选器部件 -->
          <div class="widjet --filters">
            <div class="widjet__head">
              <h3 class="uk-text-lead">Friends</h3>
            </div>
            <div class="widjet__body">
              <div class="uk-grid uk-flex-middle uk-grid-small" data-uk-grid>
                <div class="uk-width-expand@s">
                  <div class="search">
                    <div class="search__input">
                      <i class="ico_search"></i>
                      <input
                        type="search"
                        name="search"
                        placeholder="Search"
                        v-model="searchQuery"
                      />
                    </div>
                  </div>
                </div>
                <div class="uk-width-auto">
                  <select class="js-select" v-model="filterStatus">
                    <option value="">Filter: Active</option>
                    <option value="Active 1">Active 1</option>
                    <option value="Active 2">Active 2</option>
                    <option value="Active 3">Active 3</option>
                  </select>
                </div>
                <div class="uk-width-auto">
                  <button class="ico_more" href="#"></button>
                </div>
              </div>
            </div>
          </div>
          <!-- 好友列表 -->
          <div
            class="uk-grid uk-grid-small uk-child-width-1-2@m"
            data-uk-grid
          >
            <div v-for="(friend, index) in filteredFriends" :key="index">
              <div class="user-item --active">
                <div class="user-item__avatar">
                  <img :src="friend.avatar" alt="user" />
                </div>
                <div class="user-item__box">
                  <div class="user-item__name">
                    <router-link to="/chats">{{ friend.name }}</router-link>
                  </div>
                  <div class="user-item__playing">
                    Playing<b>{{ friend.playing }}</b>
                  </div>
                </div>
                <div class="user-item__more">
                  <a class="ico_more" href="#!"></a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 右侧内容区域 -->
        <div class="uk-width-1-3@xl">
          <div class="widjet --friend-requests">
            <div class="widjet__head">
              <h3 class="uk-text-lead">Friend Requests</h3>
            </div>
            <div class="widjet__body">
              <ul class="friend-requests-list">
                <li
                  class="friend-requests-item"
                  v-for="(request, index) in friendRequests"
                  :key="index"
                >
                  <div class="friend-requests-item__avatar">
                    <img :src="request.avatar" alt="user" />
                  </div>
                  <div class="friend-requests-item__name">{{ request.name }}</div>
                  <div class="friend-requests-item__action">
                    <button
                      class="confirm ico_tick-circle"
                      type="button"
                      @click="confirmRequest(index)"
                    ></button>
                    <button
                      class="reject ico_trash"
                      type="button"
                      @click="rejectRequest(index)"
                    ></button>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </main>
  </template>
  
  <script>
  export default {
    name: 'FriendsView',
    data() {
      return {
        searchQuery: '',
        filterStatus: '',
        friends: [
          {
            name: 'Lester Barry',
            playing: 'Grand Theft Auto V',
            avatar: '/assets/img/user-list-1.png',
          },
          {
            name: 'Emma Stone',
            playing: 'PUBG: BATTLEGROUNDS',
            avatar: '/assets/img/user-list-2.png',
          },
          {
            name: 'Guzman Williams',
            playing: 'Rocket League',
            avatar: '/assets/img/user-list-3.png',
          },
          {
            name: 'Olivia Mark',
            playing: 'Days Gone',
            avatar: '/assets/img/user-list-4.png',
          },
          {
            name: 'Alfred Anton',
            playing: 'Rocket League',
            avatar: '/assets/img/user-list-5.png',
          },
          {
            name: 'Bernard Tony',
            playing: 'New World',
            avatar: '/assets/img/user-list-6.png',
          },
          {
            name: 'Ronnie Win',
            playing: 'Stronghold Kingdoms',
            avatar: '/assets/img/user-list-7.png',
          },
          {
            name: 'Harry Gabriel',
            playing: 'Omno',
            avatar: '/assets/img/user-list-8.png',
          },
          {
            name: 'Sophia John',
            playing: 'Eldest Souls',
            avatar: '/assets/img/user-list-9.png',
          },
          {
            name: 'Isabella Ruke',
            playing: 'The Ascent',
            avatar: '/assets/img/user-list-10.png',
          },
          {
            name: 'Lily Lukas',
            playing: 'HighFleet',
            avatar: '/assets/img/user-list-11.png',
          },
          {
            name: 'Adrian Dima',
            playing: 'Smithy Wars',
            avatar: '/assets/img/user-list-12.png',
          },
        ],
        friendRequests: [
          {
            name: 'Fred Emil',
            avatar: '/assets/img/user-list-5.png',
          },
          {
            name: 'Leo Maxwell',
            avatar: '/assets/img/user-list-8.png',
          },
          {
            name: 'Olivia Mark',
            avatar: '/assets/img/user-list-4.png',
          },
        ],
      };
    },
    computed: {
      filteredFriends() {
        // 简单的过滤逻辑，可以根据需要进行调整
        return this.friends.filter((friend) => {
          // 搜索过滤
          const matchesSearch =
            this.searchQuery === '' ||
            friend.name.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
            friend.playing.toLowerCase().includes(this.searchQuery.toLowerCase());
  
          // 状态过滤（此处未实现实际逻辑，假设所有好友都是“Active”）
          const matchesFilter =
            this.filterStatus === '' || this.filterStatus === 'Active';
  
          return matchesSearch && matchesFilter;
        });
      },
    },
    methods: {
      confirmRequest(index) {
        // 处理确认好友请求的逻辑
        alert(`Confirmed friend request from ${this.friendRequests[index].name}`);
        // 从好友请求列表中移除，并添加到好友列表
        const newFriend = this.friendRequests.splice(index, 1)[0];
        newFriend.playing = 'New Game'; // 默认游戏，可根据实际情况调整
        this.friends.push(newFriend);
      },
      rejectRequest(index) {
        // 处理拒绝好友请求的逻辑
        alert(`Rejected friend request from ${this.friendRequests[index].name}`);
        // 从好友请求列表中移除
        this.friendRequests.splice(index, 1);
      },
    },
  };
  </script>
  
  <style scoped>
  /* 如果需要，可以在这里添加组件特定的样式 */
  </style>
  