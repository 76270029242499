<template>
    <main class="page-main">
      <h3 class="uk-text-lead">Streams</h3>
      <div data-uk-filter="target: .js-filter">
        <!-- 分类导航 -->
        <ul class="uk-subnav uk-subnav-pill">
          <li class="uk-active" data-uk-filter-control><a href="#">All</a></li>
          <li v-for="(category, index) in categories" :key="index" :data-uk-filter-control="`[data-type='${category.value}']`">
            <a href="#">{{ category.name }}</a>
          </li>
        </ul>
        <!-- 流媒体列表 -->
        <ul
          class="js-filter uk-grid-small uk-child-width-1-1 uk-child-width-1-5@xl uk-child-width-1-4@l uk-child-width-1-3@m uk-child-width-1-2@s"
          data-uk-grid
        >
          <li
            v-for="(stream, index) in streams"
            :key="index"
            :data-type="stream.type"
          >
            <div class="stream-item">
              <div class="stream-item__box">
                <div class="stream-item__media" data-uk-lightbox="video-autoplay: true">
                  <a
                    :href="stream.videoUrl"
                    :data-attrs="`width: 1280; height: 720;`"
                    :data-caption="stream.title"
                  >
                    <img :src="stream.image" :alt="stream.title" />
                  </a>
                  <div class="stream-item__info">
                    <div class="stream-item__status">Live</div>
                    <div class="stream-item__count">{{ stream.viewers }}</div>
                  </div>
                </div>
                <div class="stream-item__body">
                  <div class="stream-item__title">{{ stream.title }}</div>
                  <div class="stream-item__nicname">{{ stream.nickname }}</div>
                  <div class="stream-item__time">{{ stream.timeAgo }}</div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </main>
  </template>
  
  <script>
  export default {
    name: 'StreamsView',
    data() {
      return {
        categories: [
          { name: 'Strategy', value: 'strategy' },
          { name: 'Action', value: 'action' },
          { name: 'Adventure', value: 'adventure' },
          { name: 'Casual', value: 'casual' },
          { name: 'Simulation', value: 'simulation' },
          { name: 'Horror', value: 'horror' },
          { name: 'Anime', value: 'anime' },
          { name: 'Hunting', value: 'hunting' },
          { name: 'Racing', value: 'racing' },
          { name: 'Team Sports', value: 'team_sports' },
        ],
        streams: [
          {
            title: 'Call to Arms Gates of Hel...',
            type: 'strategy',
            videoUrl: 'https://youtu.be/IUYs3mYgEPI',
            image: '/assets/img/streem-item-1.jpg',
            viewers: '2k',
            nickname: 'Instinct',
            timeAgo: '3 hours ago',
          },
          {
            title: 'Grand Theft Auto V',
            type: 'action',
            videoUrl: 'https://youtu.be/IUYs3mYgEPI',
            image: '/assets/img/streem-item-2.jpg',
            viewers: '4k',
            nickname: 'Savoury',
            timeAgo: '2 hours ago',
          },
          {
            title: 'Natural Instincts',
            type: 'adventure',
            videoUrl: 'https://youtu.be/IUYs3mYgEPI',
            image: '/assets/img/streem-item-3.jpg',
            viewers: '2.2k',
            nickname: 'Clutchy',
            timeAgo: '5 hours ago',
          },
          {
            title: 'Tera',
            type: 'casual',
            videoUrl: 'https://youtu.be/IUYs3mYgEPI',
            image: '/assets/img/streem-item-4.jpg',
            viewers: '26k',
            nickname: 'Gameforge',
            timeAgo: '6 hours ago',
          },
          // 添加更多流媒体数据
          // ...
        ],
      };
    },
  };
  </script>
  
  <style scoped>
  /* 如果需要，可以在这里添加组件特定的样式 */
  </style>
  