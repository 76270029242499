<template>
    <main class="page-main">
      <div class="uk-grid" data-uk-grid>
        <!-- 左侧内容区域 -->
        <div class="uk-width-2-3@l">
          <div class="widjet --wallet">
            <div class="widjet__head">
              <h3 class="uk-text-lead">Wallet</h3>
            </div>
            <div class="widjet__body">
              <div class="wallet-info">
                <div class="wallet-value">{{ walletBalance }} USD</div>
                <div class="wallet-label">Available</div>
              </div>
            </div>
          </div>
        </div>
  
        <!-- 右侧内容区域 -->
        <div class="uk-width-1-3@l">
          <div class="widjet --payment-method">
            <div class="widjet__head">
              <h3 class="uk-text-lead">Payment Method</h3>
              <router-link to="/edit-payment-method">Edit</router-link>
            </div>
            <div class="widjet__body">
              <div class="payment-card">
                <div class="payment-card__head">
                  <div class="payment-card__chip">
                    <img src="/assets/img/payment-card-chip.svg" alt="chip" />
                  </div>
                  <div class="payment-card__logo">
                    <img src="/assets/img/payment-card-logo.svg" alt="logo" />
                  </div>
                </div>
                <div class="payment-card__number">**** **** **** {{ cardLastDigits }}</div>
                <div class="payment-card__value">$ {{ cardBalance }}</div>
              </div>
            </div>
          </div>
  
          <div class="widjet --activities">
            <div class="widjet__head">
              <h3 class="uk-text-lead">Activities</h3>
              <router-link to="/games-store">View All</router-link>
            </div>
            <div class="widjet__body">
              <ul class="activities-list">
                <li
                  class="activities-item"
                  v-for="(activity, index) in activities"
                  :key="index"
                >
                  <div class="activities-item__logo">
                    <router-link :to="activity.link">
                      <img :src="activity.image" alt="image" />
                    </router-link>
                  </div>
                  <div class="activities-item__info">
                    <router-link class="activities-item__title" :to="activity.link">
                      {{ activity.title }}
                    </router-link>
                    <div class="activities-item__date">{{ activity.date }}</div>
                  </div>
                  <div class="activities-item__price">{{ activity.price }}</div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </main>
  </template>
  
  <script>
  export default {
    name: 'WalletView',
    data() {
      return {
        walletBalance: '504.00',
        cardLastDigits: '6958',
        cardBalance: '18,320.00',
        activities: [
          {
            title: 'Grand Theft Auto...',
            date: '5 Jul, 2020',
            price: '-14.80 USD',
            link: '/game-profile',
            image: '/assets/img/activities-1.png',
          },
          {
            title: 'Counter-Strike: G...',
            date: '25 Apr, 2020',
            price: '-14.99 USD',
            link: '/game-profile',
            image: '/assets/img/activities-2.png',
          },
          // 您可以添加更多活动数据
        ],
      };
    },
  };
  </script>
  
  <style scoped>
  /* 如果需要，可以在这里添加组件特定的样式 */
  </style>
  