<template>
    <main class="page-main">
      <h3 class="uk-text-lead">Chats</h3>
      <div class="uk-grid uk-grid-small" data-uk-grid>
        <!-- 左侧用户列表 -->
        <div class="uk-width-1-3@l">
          <div class="chat-user-list">
            <div class="chat-user-list__box">
              <div class="chat-user-list__head">
                <div class="avatar">
                  <img src="/assets/img/profile.png" alt="profile" />
                </div>
                <a class="ico_edit" href="#"></a>
                <a class="ico_more" href="#"></a>
              </div>
              <div class="chat-user-list__search">
                <div class="search">
                  <div class="search__input">
                    <i class="ico_search"></i>
                    <input
                      type="search"
                      name="search"
                      placeholder="Search"
                      v-model="searchQuery"
                    />
                  </div>
                </div>
              </div>
              <div class="chat-user-list__body">
                <ul>
                  <li v-for="(user, index) in filteredUsers" :key="index">
                    <div :class="['user-item', user.active ? '--active' : '']">
                      <div class="user-item__avatar">
                        <img :src="user.avatar" alt="user" />
                      </div>
                      <div class="user-item__desc">
                        <div class="user-item__name">
                          <a href="#" @click.prevent="selectUser(user)">
                            {{ user.name }}
                          </a>
                        </div>
                        <div class="user-item__text">{{ user.lastMessage }}</div>
                      </div>
                      <div class="user-item__info">
                        <div class="user-item__time">{{ user.time }}</div>
                        <div
                          class="user-item__count"
                          v-if="user.unreadCount > 0"
                        >
                          {{ user.unreadCount }}
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <!-- 右侧聊天内容 -->
        <div class="uk-width-2-3@l">
          <div class="chat-messages-box">
            <div class="chat-messages-head" v-if="selectedUser">
              <div class="user-item">
                <div class="user-item__avatar">
                  <img :src="selectedUser.avatar" alt="user" />
                </div>
                <div class="user-item__desc">
                  <div class="user-item__name">{{ selectedUser.name }}</div>
                </div>
              </div>
              <div>
                <a class="ico_call" href="#!"></a>
                <a class="ico_info-circle" href="#!"></a>
              </div>
            </div>
            <div class="chat-messages-body" v-if="selectedUser">
              <div
                v-for="(message, index) in selectedUser.messages"
                :key="index"
                :class="['messages-item', message.type === 'sent' ? '--your-message' : '--friend-message']"
              >
                <div v-if="message.type === 'sent'" class="messages-item__avatar">
                  <img :src="selectedUser.avatar" alt="user" />
                </div>
                <div class="messages-item__text">{{ message.text }}</div>
              </div>
            </div>
            <div class="chat-messages-footer" v-if="selectedUser">
              <form @submit.prevent="sendMessage">
                <div class="chat-messages-form">
                  <div class="chat-messages-form-btns">
                    <button type="button" class="ico_emoji-happy"></button>
                    <button type="button" class="ico_gallery"></button>
                  </div>
                  <div class="chat-messages-form-controls">
                    <input
                      class="chat-messages-input"
                      type="text"
                      placeholder="Type a message"
                      v-model="newMessage"
                    />
                  </div>
                  <div class="chat-messages-form-btn">
                    <button class="ico_microphone" type="button"></button>
                  </div>
                </div>
              </form>
            </div>
            <div v-else class="no-chat-selected">
              <p>Please select a chat to start messaging.</p>
            </div>
          </div>
        </div>
      </div>
    </main>
  </template>
  
  <script>
  export default {
    name: 'ChatsView',
    data() {
      return {
        searchQuery: '',
        users: [
          {
            name: 'Lester Barry',
            avatar: '/assets/img/user-list-1.png',
            lastMessage: "Let’s play now!",
            time: '4m',
            unreadCount: 1,
            active: true,
            messages: [
              { type: 'received', text: 'Which game you play now?' },
              { type: 'sent', text: 'I play Stronghold Kingdoms' },
              // 添加更多消息
            ],
          },
          {
            name: 'Emma Stone',
            avatar: '/assets/img/user-list-2.png',
            lastMessage: 'Are you here?',
            time: '10m',
            unreadCount: 2,
            active: true,
            messages: [
              // 消息列表
            ],
          },
          {
            name: 'Sofia Dior',
            avatar: '/assets/img/user-list-3.png',
            lastMessage: 'You: Good I will wait you in the ga...',
            time: '1h',
            unreadCount: 0,
            active: false,
            messages: [
              { type: 'received', text: 'Which game you play now?' },
              { type: 'sent', text: 'I play Stronghold Kingdoms' },
              { type: 'received', text: 'What type of game is this? is it a strategy game?' },
              { type: 'sent', text: 'Yes' },
              { type: 'received', text: 'Wow! it seems amazing I will download it!' },
              { type: 'sent', text: 'Good I will wait you in the game!' },
            ],
          },
          // 添加更多用户
        ],
        selectedUser: null,
        newMessage: '',
      };
    },
    computed: {
      filteredUsers() {
        return this.users.filter((user) => {
          return (
            user.name.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
            user.lastMessage.toLowerCase().includes(this.searchQuery.toLowerCase())
          );
        });
      },
    },
    methods: {
      selectUser(user) {
        this.selectedUser = user;
        // 将未读消息数清零
        user.unreadCount = 0;
      },
      sendMessage() {
        if (this.newMessage.trim() !== '') {
          this.selectedUser.messages.push({
            type: 'sent',
            text: this.newMessage.trim(),
          });
          this.newMessage = '';
          // 在此添加发送消息到服务器的逻辑
        }
      },
    },
  };
  </script>
  
  <style scoped>
  .no-chat-selected {
    text-align: center;
    padding: 50px;
    color: #888;
  }
  </style>
  