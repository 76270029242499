<template>
    <main class="page-main">
      <div class="uk-grid" data-uk-grid>
        <!-- 左侧内容区域 -->
        <div class="uk-width-2-3@l">
          <div class="widjet --filters">
            <div class="widjet__head">
              <h3 class="uk-text-lead">Market</h3>
            </div>
            <div class="widjet__body">
              <div
                class="uk-grid uk-child-width-1-4@xl uk-child-width-1-2@s uk-flex-middle uk-grid-small"
                data-uk-grid
              >
                <div class="uk-width-1-1">
                  <div class="search">
                    <div class="search__input">
                      <i class="ico_search"></i>
                      <input
                        type="search"
                        name="search"
                        placeholder="Search"
                        v-model="searchQuery"
                      />
                    </div>
                    <div class="search__btn">
                      <button type="button">
                        <i class="ico_microphone"></i>
                      </button>
                    </div>
                  </div>
                </div>
                <div>
                  <select class="js-select" v-model="sortBy">
                    <option value="">Sort By: Popular</option>
                    <option value="Popular Items 1">Popular Items 1</option>
                    <option value="Popular Items 2">Popular Items 2</option>
                    <option value="Popular Items 3">Popular Items 3</option>
                  </select>
                </div>
                <div>
                  <select class="js-select" v-model="selectedGame">
                    <option value="">Game: All</option>
                    <option value="Game 1">Game 1</option>
                    <option value="Game 2">Game 2</option>
                    <option value="Game 3">Game 3</option>
                  </select>
                </div>
                <div></div>
                <div class="uk-text-right">
                  <a href="#!">{{ filteredItems.length }} items</a>
                </div>
              </div>
            </div>
          </div>
  
          <!-- 市场项目列表 -->
          <div class="widjet --market">
            <div class="widjet__body" v-for="(item, index) in filteredItems" :key="index">
              <div class="widjet-game">
                <div class="widjet-game__media">
                  <router-link :to="item.link">
                    <img :src="item.image" alt="image" />
                  </router-link>
                </div>
                <div class="widjet-game__info">
                  <div class="widjet-game__title">
                    <router-link :to="item.link">{{ item.title }}</router-link>
                  </div>
                  <div class="widjet-game__game-name">{{ item.gameName }}</div>
                  <div class="widjet-game__starting">
                    Starting at:
                    <b :class="{ 'price-down': item.priceDown }">{{ item.price }}</b>
                    <span :data-uk-icon="item.priceDirection"></span>
                  </div>
                  <div class="widjet-game__quantity">
                    Quantity:<b>{{ item.quantity }}</b>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
  
        <!-- 右侧内容区域 -->
        <div class="uk-width-1-3@l">
          <div class="widjet --upload">
            <div class="widjet__head">
              <h3 class="uk-text-lead">Upload Item</h3>
            </div>
            <div class="widjet__body">
              <select class="js-select uk-flex-1" v-model="selectedUploadGame">
                <option value="">Select Game</option>
                <option value="Game 1">Game 1</option>
                <option value="Game 2">Game 2</option>
                <option value="Game 3">Game 3</option>
              </select>
              <button class="uk-button uk-button-secondary" type="button" @click="uploadItem">
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    </main>
  </template>
  
  <script>
  export default {
    name: 'MarketView',
    data() {
      return {
        searchQuery: '',
        sortBy: '',
        selectedGame: '',
        selectedUploadGame: '',
        items: [
          {
            title: 'Halloween Chest',
            gameName: 'Hero Siege',
            price: '$0.04',
            priceDown: false,
            priceDirection: 'arrow-up',
            quantity: '25,341',
            image: '/assets/img/game-1.jpg',
            link: '/game-profile',
          },
          {
            title: 'High Quality Crate',
            gameName: 'Rust',
            price: '$5.55',
            priceDown: true,
            priceDirection: 'arrow-down',
            quantity: '1,271',
            image: '/assets/img/game-2.jpg',
            link: '/game-profile',
          },
          {
            title: 'Trifecta - SCAR-L',
            gameName: "PLAYERUNKNOWN'S BATTLEGROUNDS",
            price: '$0.95',
            priceDown: false,
            priceDirection: '',
            quantity: '2,878',
            image: '/assets/img/market-item-1.jpg',
            link: '/game-profile',
          },
          {
            title: 'Operation Broken Fang Case',
            gameName: 'Counter-Strike: Global Offensive',
            price: '$0.55',
            priceDown: false,
            priceDirection: '',
            quantity: '401,244',
            image: '/assets/img/game-3.jpg',
            link: '/game-profile',
          },
          // 添加更多项目数据
        ],
      };
    },
    computed: {
      filteredItems() {
        // 简单的过滤示例，您可以根据需求添加更复杂的过滤逻辑
        return this.items.filter((item) => {
          // 搜索过滤
          const matchesSearch =
            this.searchQuery === '' ||
            item.title.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
            item.gameName.toLowerCase().includes(this.searchQuery.toLowerCase());
  
          // 游戏过滤
          const matchesGame =
            this.selectedGame === '' || item.gameName === this.selectedGame;
  
          return matchesSearch && matchesGame;
        });
      },
    },
    methods: {
      uploadItem() {
        if (this.selectedUploadGame) {
          // 实现上传物品的功能，这里可以跳转到上传页面，或者弹出一个模态框
          alert(`Uploading item for ${this.selectedUploadGame}`);
        } else {
          alert('Please select a game to upload an item.');
        }
      },
    },
  };
  </script>
  
  <style scoped>
  /* 如果需要，可以在这里添加组件特定的样式 */
  </style>
  