<template>
  <aside class="sidebar is-show" id="sidebar">
    <div class="sidebar-box">
      <ul class="uk-nav">
        <li :class="{ 'uk-active': isActiveRoute('/') }">
          <router-link to="/"><i class="ico_home"></i><span>Home</span></router-link>
        </li>
        <li class="uk-nav-header">Account</li>

        <li :class="{ 'uk-active': isActiveRoute('/profile') }">
          <a href="#" @click.prevent="navigate('/profile')">
            <i class="ico_profile"></i><span>Profile</span>
          </a>
          <!-- <router-link to="/profile"><i class="ico_profile"></i><span>Profile</span></router-link> -->
        </li>

        <li :class="{ 'uk-active': isActiveRoute('/favourites') }">
          <a href="#" @click.prevent="navigate('/favourites')">
            <i class="ico_favourites"></i><span>Favourites</span><span class="count">15</span>
          </a>
        </li>

        <li :class="{ 'uk-active': isActiveRoute('/chats') }">
          <a href="#" @click.prevent="navigate('/chats')">
            <i class="ico_chats"></i><span>Chats</span>
          </a>
        </li>

        <li :class="{ 'uk-active': isActiveRoute('/friends') }">

          <a href="#" @click.prevent="navigate('/friends')">
            <i class="ico_friends"></i><span>Friends</span>
          </a>
          <!-- <router-link to="/friends"><i class="ico_friends"></i><span>Friends</span></router-link> -->
        </li>
        <li :class="{ 'uk-active': isActiveRoute('/wallet') }">
          <a href="#" @click.prevent="navigate('/wallet')">
            <i class="ico_wallet"></i><span>Wallet</span>
          </a>
        </li>
        <li class="uk-nav-header">Main</li>

        <li :class="{ 'uk-active': isActiveRoute('/games-store') }">
          <a href="#" @click.prevent="navigate('/games-store')">
            <i class="ico_store"></i><span>Store</span>
          </a>

          <!-- <router-link to="/games-store"><i class="ico_store"></i><span>Store</span></router-link> -->
        </li>

        <li :class="{ 'uk-active': isActiveRoute('/CardVerification') }">
          <router-link to="/CardVerification"><i class="ico_store"></i><span>Code Redemption</span></router-link>
        </li>

        
        <li :class="{ 'uk-active': isActiveRoute('/market') }">
          <a href="#" @click.prevent="navigate('/market')">
            <i class="ico_market"></i><span>Market</span>
          </a>
        </li>


        <li :class="{ 'uk-active': isActiveRoute('/streams') }">
          <a href="#" @click.prevent="navigate('/streams')">
            <i class="ico_streams"></i><span>Streams</span>
          </a>
        </li>
        <li :class="{ 'uk-active': isActiveRoute('/community') }">
          <a href="#" @click.prevent="navigate('/community')">
            <i class="ico_community"></i><span>Community</span>
          </a>
          <!-- <router-link to="/community"><i class="ico_community"></i><span>Community</span></router-link> -->
        </li>
        <li class="uk-nav-header">Support</li>
        <li>
          <a href="#" @click.prevent="openModal('modal-report')">
            <i class="ico_report"></i><span>Report</span>
          </a>
        </li>
        <li>
          <a href="#" @click.prevent="openModal('modal-help')">
            <i class="ico_help"></i><span>Help</span>
          </a>
        </li>
      </ul>
    </div>
  </aside>
</template>

<script>
/* global UIkit */
import { useRoute, useRouter } from 'vue-router';

export default {
  name: 'SidebarComponent',
  setup() {
    const route = useRoute();
    const router = useRouter();

    // 判断当前路径是否为激活状态
    const isActiveRoute = (path) => {
      return route.path === path;
    };

    // 模拟未开放页面路径
    const unavailablePages = ['/profile','/chats','/friends','/games-store','/community','/favourites', '/wallet', '/market', '/streams'];

    // 判断页面是否开放
    const isPageAvailable = (path) => {
      return !unavailablePages.includes(path);
    };

    // 导航到页面或显示弹窗
    const navigate = (path) => {
      if (isPageAvailable(path)) {
        router.push(path); // 如果页面开放，正常导航
      } else {
        // 如果页面未开放，显示弹窗提示
        UIkit.modal.alert('This feature is not available yet.');
      }
    };

    // 打开模态框
    const openModal = (modalId) => {
      const modalElement = document.getElementById(modalId);
      if (modalElement) {
        UIkit.modal(modalElement).show();
      }
    };

    return {
      isActiveRoute,
      navigate,
      openModal,
    };
  },
};
</script>

<style scoped>
/* 如果需要，可以在这里添加组件特定的样式 */
</style>
