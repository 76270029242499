<template>
    <main class="page-main">
      <div class="uk-grid" data-uk-grid>
        <!-- 左侧内容区域 -->
        <div class="uk-width-2-3@l">
          <div class="widjet --filters">
            <div class="widjet__head">
              <h3 class="uk-text-lead">Community</h3>
            </div>
            <div class="widjet__body">
              <div
                class="uk-grid uk-child-width-1-4@xl uk-child-width-1-2@s uk-flex-middle uk-grid-small"
                data-uk-grid
              >
                <div class="uk-width-1-1">
                  <div class="search">
                    <div class="search__input">
                      <i class="ico_search"></i>
                      <input
                        type="search"
                        name="search"
                        placeholder="Search"
                        v-model="searchQuery"
                      />
                    </div>
                    <div class="search__btn">
                      <button type="button">
                        <i class="ico_microphone"></i>
                      </button>
                    </div>
                  </div>
                </div>
                <div>
                  <select class="js-select" v-model="selectedForum">
                    <option value="">Forums: Game</option>
                    <option value="Game Forums 1">Game Forums 1</option>
                    <option value="Game Forums 2">Game Forums 2</option>
                    <option value="Game Forums 3">Game Forums 3</option>
                  </select>
                </div>
                <div>
                  <select class="js-select" v-model="sortBy">
                    <option value="">Sort By: Popular</option>
                    <option value="Most Popular 1">Most Popular 1</option>
                    <option value="Most Popular 2">Most Popular 2</option>
                    <option value="Most Popular 3">Most Popular 3</option>
                  </select>
                </div>
                <div></div>
                <div class="uk-text-right">
                  <a href="/game-profile">{{ filteredGames.length }} items</a>
                </div>
              </div>
            </div>
          </div>
  
          <!-- 游戏卡片列表 -->
          <div
            class="game-card --horizontal --small"
            v-for="(game, index) in filteredGames"
            :key="index"
          >
            <div class="game-card__box">
              <div class="game-card__media">
                <router-link :to="game.link">
                  <img :src="game.image" :alt="game.title" />
                </router-link>
              </div>
              <div class="game-card__info">
                <router-link class="game-card__title" :to="game.link">
                  {{ game.title }}
                </router-link>
                <div class="game-card__discussion">
                  discussion threads<b>{{ game.discussionThreads }}</b>
                </div>
              </div>
              <div class="game-card__more">
                <router-link :to="game.link">
                  <i class="ico_more"></i>
                </router-link>
              </div>
            </div>
          </div>
        </div>
  
        <!-- 右侧内容区域 -->
        <div class="uk-width-1-3@l">
          <div class="widjet --my-list">
            <div class="widjet__head">
              <h3 class="uk-text-lead">My Groups</h3>
              <router-link to="/game-profile">View All</router-link>
            </div>
            <div class="widjet__body">
              <ul class="my-game-list">
                <li class="my-game-item" v-for="(group, index) in myGroups" :key="index">
                  <div class="my-game-item__img">
                    <img :src="group.image" alt="image" />
                  </div>
                  <div class="my-game-item__title">{{ group.title }}</div>
                  <div class="my-game-item__more">
                    <router-link :to="group.link">
                      <i class="ico_more"></i>
                    </router-link>
                  </div>
                </li>
              </ul>
              <button class="creat-list-btn" @click="createNewGroup">
                <i class="ico_add-square"></i><span>Create New Group</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </main>
  </template>
  
  <script>
  export default {
    name: 'CommunityView',
    data() {
      return {
        searchQuery: '',
        selectedForum: '',
        sortBy: '',
        games: [
          {
            title: 'Crimson Resurrection',
            discussionThreads: '5,200',
            image: '/assets/img/game-3.jpg',
            link: '/game-profile',
          },
          {
            title: 'Strife of Retribution',
            discussionThreads: '11,860',
            image: '/assets/img/game-4.jpg',
            link: '/game-profile',
          },
          {
            title: 'Garden and Dimension',
            discussionThreads: '5,190',
            image: '/assets/img/game-10.jpg',
            link: '/game-profile',
          },
          {
            title: 'Dust and Ambition',
            discussionThreads: '6,230',
            image: '/assets/img/game-6.jpg',
            link: '/game-profile',
          },
          {
            title: 'Command of Retribution',
            discussionThreads: '16,200',
            image: '/assets/img/game-7.jpg',
            link: '/game-profile',
          },
          // 添加更多游戏数据
        ],
        myGroups: [
          {
            title: 'Galaxy Voyage',
            image: '/assets/img/game-1.jpg',
            link: '/game-profile',
          },
          {
            title: 'Prime Games',
            image: '/assets/img/game-2.jpg',
            link: '/game-profile',
          },
          {
            title: 'Clash of Friction',
            image: '/assets/img/game-3.jpg',
            link: '/game-profile',
          },
          {
            title: 'Command of Retribution',
            image: '/assets/img/game-4.jpg',
            link: '/game-profile',
          },
          // 添加更多群组数据
        ],
      };
    },
    computed: {
      filteredGames() {
        // 简单的过滤示例，您可以根据需求添加更复杂的过滤逻辑
        return this.games.filter((game) => {
          // 搜索过滤
          const matchesSearch =
            this.searchQuery === '' ||
            game.title.toLowerCase().includes(this.searchQuery.toLowerCase());
  
          // 论坛过滤（此处未实现，假设所有游戏都在同一个论坛中）
          const matchesForum = this.selectedForum === '' || true;
  
          return matchesSearch && matchesForum;
        });
      },
    },
    methods: {
      createNewGroup() {
        // 实现创建新群组的功能
        alert('Create New Group clicked');
      },
    },
  };
  </script>
  
  <style scoped>
  /* 如果需要，可以在这里添加组件特定的样式 */
  </style>
  