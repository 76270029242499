<template>
    <main class="page-main">
      <div class="uk-grid" data-uk-grid>
        <!-- 左侧内容区域 -->
        <div class="uk-width-2-3@l">
          <div class="widjet --filters">
            <div class="widjet__head">
              <h3 class="uk-text-lead">My Favourites</h3>
            </div>
            <div class="widjet__body">
              <div
                class="uk-grid uk-child-width-1-4@xl uk-child-width-1-2@s uk-flex-middle uk-grid-small"
                data-uk-grid
              >
                <div class="uk-width-1-1">
                  <div class="search">
                    <div class="search__input">
                      <i class="ico_search"></i>
                      <input type="search" name="search" placeholder="Search" />
                    </div>
                    <div class="search__btn">
                      <button type="button">
                        <i class="ico_microphone"></i>
                      </button>
                    </div>
                  </div>
                </div>
                <div>
                  <select class="js-select">
                    <option value="">Sort By: Price</option>
                    <option value="Price 1">Price 1</option>
                    <option value="Price 2">Price 2</option>
                    <option value="Price 3">Price 3</option>
                  </select>
                </div>
                <div>
                  <select class="js-select">
                    <option value="">Category: All</option>
                    <option value="Category 1">Category 1</option>
                    <option value="Category 2">Category 2</option>
                    <option value="Category 3">Category 3</option>
                  </select>
                </div>
                <div>
                  <select class="js-select">
                    <option value="">Platform: All</option>
                    <option value="Platform 1">Platform 1</option>
                    <option value="Platform 2">Platform 2</option>
                    <option value="Platform 3">Platform 3</option>
                  </select>
                </div>
                <div class="uk-text-right">
                  <a href="#!">{{ favourites.length }} items</a>
                </div>
              </div>
            </div>
          </div>
  
          <!-- 收藏的游戏列表 -->
          <div
            class="game-card --horizontal favourites-game"
            v-for="(game, index) in favourites"
            :key="index"
          >
            <div class="game-card__box">
              <div class="game-card__media">
                <router-link :to="game.link">
                  <img :src="game.image" :alt="game.title" />
                </router-link>
              </div>
              <div class="game-card__info">
                <router-link class="game-card__title" :to="game.link">
                  {{ game.title }}
                </router-link>
                <div class="game-card__genre">{{ game.genre }}</div>
                <div class="game-card__rating-and-price">
                  <div class="game-card__rating">
                    <span>{{ game.rating }}</span><i class="ico_star"></i>
                  </div>
                  <div class="game-card__price">
                    <span>{{ game.price }}</span>
                  </div>
                </div>
                <div class="game-card__bottom">
                  <div class="game-card__platform">
                    <i class="ico_windows" v-if="game.platform.includes('windows')"></i>
                    <i class="ico_apple" v-if="game.platform.includes('apple')"></i>
                  </div>
                </div>
              </div>
              <div class="game-card__more">
                <router-link :to="game.link">
                  <i class="ico_more"></i>
                </router-link>
              </div>
            </div>
          </div>
        </div>
  
        <!-- 右侧内容区域 -->
        <div class="uk-width-1-3@l">
          <div class="widjet --my-list">
            <div class="widjet__head">
              <h3 class="uk-text-lead">My Lists</h3>
            </div>
            <div class="widjet__body">
              <ul class="my-game-list">
                <li
                  class="my-game-item"
                  v-for="(list, index) in myLists"
                  :key="index"
                >
                  <div
                    class="my-game-item__letter"
                    :style="{ background: list.color }"
                  >
                    {{ list.initial }}
                  </div>
                  <div class="my-game-item__title">{{ list.title }}</div>
                  <div class="my-game-item__more">
                    <router-link :to="list.link">
                      <i class="ico_more"></i>
                    </router-link>
                  </div>
                </li>
              </ul>
              <button class="creat-list-btn" @click="createNewList">
                <i class="ico_add-square"></i><span>Create New List</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </main>
  </template>
  
  <script>
  export default {
    name: 'FavouritesView',
    data() {
      return {
        favourites: [
          {
            title: 'Werewolf Complex',
            genre: 'Strategy / Medieval',
            rating: '3.9',
            price: 'Free',
            link: '/game-profile',
            image: '/assets/img/game-1.jpg',
            platform: ['windows', 'apple'],
          },
          {
            title: 'Domination and Shadows',
            genre: 'Battle Royal / Survival / Multiplayer',
            rating: '4.2',
            price: '$29.99 USD',
            link: '/game-profile',
            image: '/assets/img/game-2.jpg',
            platform: ['windows', 'apple'],
          },
          {
            title: 'Command and Words',
            genre: 'Strategy / Action / Medieval',
            rating: '4.4',
            price: '$29.99 USD',
            link: '/game-profile',
            image: '/assets/img/game-3.jpg',
            platform: ['windows', 'apple'],
          },
          {
            title: 'Spirit Mercenary',
            genre: 'Hunting / Horror / Multiplayer',
            rating: '4.3',
            price: '$39.99 USD',
            link: '/game-profile',
            image: '/assets/img/game-4.jpg',
            platform: ['windows', 'apple'],
          },
          // 您可以添加更多收藏的游戏数据
        ],
        myLists: [
          {
            initial: 'S',
            title: 'Strategy Games',
            color: '#4398E8',
            link: '/favourites',
          },
          {
            initial: 'A',
            title: 'Action Games',
            color: '#FF5B5B',
            link: '/favourites',
          },
          {
            initial: 'M',
            title: 'Multiplayer Games',
            color: '#C73C00',
            link: '/favourites',
          },
          {
            initial: 'B',
            title: 'Best Games',
            color: '#939393',
            link: '/favourites',
          },
          {
            initial: 'R',
            title: 'Racing Games',
            color: '#FF3ED4',
            link: '/favourites',
          },
          // 您可以添加更多列表数据
        ],
      };
    },
    methods: {
      createNewList() {
        // 实现创建新列表的功能
        alert('Create New List clicked');
      },
    },
  };
  </script>
  
  <style scoped>
  /* 如果需要，可以在这里添加组件特定的样式 */
  </style>
  