<template>
  <div class="page-modals">
    <!-- Report Modal -->
    <div class="uk-flex-top" id="modal-report" data-uk-modal>
      <div class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
        <button class="uk-modal-close-default" type="button" data-uk-close></button>
        <h2 class="uk-modal-title">Report</h2>
        <!-- 表单内容 -->
        <!-- 您可以复制原始HTML中的模态框内容到这里 -->
        <!-- 以下是原始HTML中的内容 -->
        <form class="uk-form-stacked" action="#">
          <div class="uk-margin">
            <div class="uk-form-label">Subject</div>
            <div class="uk-form-controls">
              <select class="js-select">
                <option value="">Choose Subject</option>
                <option value="Subject 1">Subject 1</option>
                <option value="Subject 2">Subject 2</option>
                <option value="Subject 3">Subject 3</option>
              </select>
            </div>
          </div>
          <div class="uk-margin">
            <div class="uk-form-label">Details</div>
            <div class="uk-form-controls">
              <textarea class="uk-textarea" name="details" placeholder="Try to include all details..."></textarea>
            </div>
            <div class="uk-form-controls uk-margin-small-top">
              <div data-uk-form-custom>
                <input type="file">
                <button class="uk-button uk-button-default" type="button" tabindex="-1">
                  <i class="ico_attach-circle"></i><span>Attach File</span>
                </button>
              </div>
            </div>
          </div>
          <div class="uk-margin">
            <div class="uk-grid uk-flex-right" data-uk-grid>
              <div>
                <button class="uk-button uk-button-small uk-button-link">Cancel</button>
              </div>
              <div>
                <button class="uk-button uk-button-small uk-button-danger">Submit</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <!-- Help Modal -->
    <div class="uk-flex-top" id="modal-help" data-uk-modal>
      <div class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
        <button class="uk-modal-close-default" type="button" data-uk-close></button>
        <h2 class="uk-modal-title">Help</h2>
        <!-- 帮助内容 -->
        <!-- 以下是原始HTML中的内容 -->
        <div class="search">
          <div class="search__input">
            <i class="ico_search"></i>
            <input type="search" name="search" placeholder="Search">
          </div>
          <div class="search__btn">
            <button type="button">
              <i class="ico_microphone"></i>
            </button>
          </div>
        </div>
        <div class="uk-margin-small-left uk-margin-small-bottom uk-margin-medium-top">
          <h4>Popular Q&A</h4>
          <ul>
            <li><img src="assets/img/svgico/clipboard-text.svg" alt="icon"><span>How to Upload Your Developed Game</span></li>
            <li><img src="assets/img/svgico/clipboard-text.svg" alt="icon"><span>How to Go Live Stream</span></li>
            <li><img src="assets/img/svgico/clipboard-text.svg" alt="icon"><span>Get in touch with the Creator Support Team</span></li>
          </ul>
          <ul>
            <li><a href="#!">browse all articles</a></li>
            <li><a href="#!">Send Feedback</a></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModalComponent',
};
</script>

<style scoped>
/* 组件样式（如果有需要） */
</style>
