<template>
    <main class="page-main">
      <ul class="uk-breadcrumb">
        <li>
          <router-link to="/games-store">
            <span data-uk-icon="chevron-left"></span>
            <span>Back to Store</span>
          </router-link>
        </li>
        <li>
          <span>{{ game.title }}</span>
        </li>
      </ul>
      <h3 class="uk-text-lead">{{ game.title }}</h3>
      <div class="uk-grid uk-grid-small" data-uk-grid>
        <div class="uk-width-2-3@s">
          <div class="gallery">
            <div class="js-gallery-big gallery-big">
              <div class="swiper">
                <div class="swiper-wrapper">
                  <div
                    class="swiper-slide"
                    v-for="(image, index) in game.images"
                    :key="index"
                  >
                    <img :src="image" alt="image" />
                  </div>
                </div>
              </div>
            </div>
            <div class="js-gallery-small gallery-small">
              <div class="swiper">
                <div class="swiper-wrapper">
                  <div
                    class="swiper-slide"
                    v-for="(image, index) in game.images"
                    :key="index"
                  >
                    <img :src="image" alt="image" />
                  </div>
                </div>
              </div>
              <div class="swiper-pagination"></div>
            </div>
          </div>
        </div>
        <div class="uk-width-1-3@s">
          <div class="game-profile-card">
            <div class="game-profile-card__media">
              <img :src="game.coverImage" alt="game-profile-card" />
            </div>
            <div class="game-profile-card__intro">
              <span>{{ game.description }}</span>
            </div>
            <ul class="game-profile-card__list">
              <li>
                <div>Reviews:</div>
                <div class="game-card__rating">
                  <span>{{ game.rating }}</span><i class="ico_star"></i>
                  <span class="rating-vote">({{ game.reviewsCount }})</span>
                </div>
              </li>
              <li>
                <div>Release date:</div>
                <div>{{ game.releaseDate }}</div>
              </li>
              <li>
                <div>Developer:</div>
                <div>{{ game.developer }}</div>
              </li>
              <li>
                <div>Platforms:</div>
                <div class="game-card__platform">
                  <i class="ico_windows" v-if="game.platforms.includes('windows')"></i>
                  <i class="ico_apple" v-if="game.platforms.includes('apple')"></i>
                </div>
              </li>
            </ul>
            <ul class="game-profile-card__type">
              <li v-for="(genre, index) in game.genres" :key="index">
                <span>{{ genre }}</span>
              </li>
            </ul>
          </div>
          <div class="game-profile-price">
            <div class="game-profile-price__value">{{ game.price }} USD</div>
            <button
              class="uk-button uk-button-danger uk-width-1-1"
              type="button"
              @click="buyNow"
            >
              <span class="ico_shopping-cart"></span><span>Buy Now</span>
            </button>
            <button
              class="uk-button uk-button-primary uk-width-1-1"
              type="button"
              @click="addToFavourites"
            >
              <span class="ico_add-square"></span><span>Add to Favourites</span>
            </button>
          </div>
        </div>
      </div>
    </main>
  </template>
  
  <script>
  export default {
    name: 'GameProfileView',
    data() {
      return {
        game: {
          title: 'Team Host',
          images: [
            '/assets/img/c0.jpg',
            '/assets/img/c1.jpeg',
            '/assets/img/c2.jpeg',
            '/assets/img/c3.jpeg',
            '/assets/img/c4.jpeg',
          ],
          coverImage: '/assets/img/game-profile-card.jpg',
          description:
            'TeamHost is a simulation and strategy game of managing a city struggling to survive after apocalyptic global cooling.',
          rating: '4.7',
          reviewsCount: 433,
          releaseDate: '24 Apr, 2018',
          developer: '11 bit studios',
          platforms: ['windows', 'apple'],
          genres: ['Strategy', 'Survival', 'City Builder', 'Dark'],
          price: '13.99',
        },
      };
    },
    methods: {
      buyNow() {
        // 实现购买功能
        alert('Buy Now clicked');
      },
      addToFavourites() {
        // 实现添加到收藏功能
        alert('Added to Favourites');
      },
    },
  };
  </script>
  
  <style scoped>
  /* 如果需要，可以在这里添加组件特定的样式 */
  </style>
  